import React, {
  ChangeEvent,
  FC,
  PropsWithChildren,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useApp } from "../../../contexts/AppContext";
import { Booking, BookingUser } from "../../../interfaces/booking.interface";
import {
  getBooking,
  issueBooking,
  rejectPaidBooking,
} from "../../../utils/rest-api/bookings";
import {
  Badge,
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Table,
} from "react-bootstrap";
import { RateCategory } from "../common/Rates";
import { Ticket } from "../../../interfaces/ticket.interface";
import dayjs from "dayjs";
import { BookingStatus } from "../../../enums/booking.enum";
import { useAuth } from "../../../contexts/AuthContext";
import { parseErrorToObject } from "../../../utils/parseErrorToObject";
import {
  BookingActionButton,
  BookingActionModalProps,
} from "../../../pages/booking";

export interface ItinerayFormProps extends PropsWithChildren {
  bookingId?: string;
  readonly?: boolean;
}

const ItinerayForm: FC<ItinerayFormProps> = (props) => {
  const [booking, setBooking] = useState<Booking>({
    _id: undefined,
    schedule: {
      provider: { _id: "", name: "" },
      origin: { _id: "", country: "", port: "" },
      destination: { _id: "", country: "", port: "" },
      departure: "",
    },
    tickets: [],
    status: "",
    createdAt: "",
    updatedAt: "",
    processedBy: {
      _id: "",
      email: "",
      role: "",
    },
    user: {
      _id: "",
      email: "",
      role: "",
    },
    totalPrice: 0,
    expiredAt: undefined,
    isTwoWay: false,
  });

  const navigate = useNavigate();
  const { showLoader, hideLoader } = useApp();

  useEffect(() => {
    if (props.bookingId) {
      showLoader();
      getBooking(props.bookingId)
        .then((res) => {
          setBooking(res.data.data);
        })
        .catch((e) => {
          toast.error(e.response.data.message ?? e.message);
        })
        .finally(() => hideLoader());
    }
  }, []);

  const { user } = useAuth();

  useEffect(() => {
    if (booking._id !== undefined && !props.readonly) {
      if (booking.status != BookingStatus.PROCESSING) {
        toast.error("You are not allowed to process this booking");
        navigate("/bookings");
      } else if ((booking.processedBy as BookingUser)._id != user?._id) {
        toast.error("This booking already processed by another admin");
        navigate("/bookings");
      }
    }
  }, [booking]);

  const [invalidTicketProps, setInvalidTicketProps] = useState<{
    tickets: Array<{ ticketCode: string }>;
  }>({
    tickets: [],
  });

  useEffect(() => {
    console.log(invalidTicketProps);
  }, [invalidTicketProps]);

  const getPassengers = () => {
    if (booking && booking.tickets) {
      const passengers = booking.tickets.reduce(
        (
          current: Array<{ category: RateCategory; count: number }>,
          prev: Ticket
        ) => {
          const data = current.find((dt) => dt.category == prev.rateCategory);
          data!.count += 1;
          return current;
        },
        [
          { category: RateCategory.ADULT, count: 0 },
          { category: RateCategory.CHILD, count: 0 },
          { category: RateCategory.INFANT, count: 0 },
        ]
      );
      return passengers
        .map((dt) => {
          return dt.count + " " + dt.category;
        })
        .join(", ");
    }
    return "";
  };

  const onTicketCodeChange = (ticket: Ticket, value: string) => {
    ticket.ticketCode = value;
    setBooking({ ...booking });
  };

  const onProcess = () => {
    const ticketsPayload = booking.tickets.map((ticket) => {
      return {
        ticketId: ticket._id,
        ticketCode: ticket.ticketCode,
      };
    });
    showLoader();
    issueBooking(booking._id!, ticketsPayload)
      .then((res) => {
        toast.success("Ticket successfully issued");
        navigate("/bookings");
      })
      .catch((e) => {
        handleError(e);
      })
      .finally(() => {
        hideLoader();
      });
  };

  const [modal, setModal] = useState<BookingActionModalProps>({
    show: false,
    action: undefined,
    booking: undefined,
    reason: "",
  });
  const renderModalTitle = () => {
    switch (modal.action) {
      case BookingActionButton.APPROVE_CANCELLATION:
        return "Approve Cancellation Request";
      case BookingActionButton.REJECT_CANCELLATION:
        return "Reject Cancellation Request";
      case BookingActionButton.REJECT_PAID:
        return "Reject Paid Booking";
    }
  };

  const closeModal = () => {
    setModal({
      show: false,
      action: undefined,
      booking: undefined,
      reason: "",
    });
  };

  const onModalProcessButtonClick = () => {
    switch (modal.action) {
      case BookingActionButton.REJECT_PAID:
        showLoader();
        rejectPaidBooking(modal.booking?._id!, modal.reason)
          .then((res) => {
            toast.success("Booking successfuly rejected!");
            closeModal();
            // fetchData();
            navigate("/bookings");
          })
          .catch((e) => {
            if (e.response.status == 400) {
              setModal({
                ...modal,
                invalidReason: e.response.data?.message?.reason,
              });
            } else {
              toast.error(
                e.response?.data.message || e.response?.error || "Network error"
              );
            }
          })
          .finally(() => {
            hideLoader();
          });
        break;
      default:
        break;
    }
  };

  const handleError = (err: any) => {
    if (err.response.data) {
      if (typeof err.response.data?.message == "object") {
        const data: any = parseErrorToObject(err.response.data.message);
        setInvalidTicketProps(data);
      } else {
        toast.error(err.response.data?.message ?? err.response.data.error);
      }
    } else {
      toast.error("Please check your network connection");
    }
  };

  return (
    <div style={{ marginTop: "2rem", marginBottom: "2rem" }}>
      <style>
        {`@media print {
  /* remove header footer */
  @page {
    margin: 0;
  }

  /* Optional: Style the printed content */
  body {
    margin: 0;
    padding: 0;
  }

  .noPrint {
    display: none !important;
  }`}
      </style>
      <div className="d-flex flex-column">
        <div
          className="d-flex flex-row justify-content-end flex-grow-1 gap-2 noPrint"
          style={{ paddingLeft: "2rem", paddingRight: "2rem" }}
        >
          <Button
            variant="secondary"
            className="w-sm-100"
            onClick={(e) => {
              navigate(`/bookings/${booking._id}`);
            }}
          >
            Back to booking
          </Button>
          <Button
            variant="primary"
            className="w-sm-100"
            onClick={(e) => {
              window.print();
            }}
          >
            <div className="d-flex flex-row gap-1 justify-content-center w-20 h-20">
              <div>Print</div>
              <img src="/img/icons/printIcon.svg" alt="image" />
            </div>
          </Button>
        </div>

        <div
          style={{
            minWidth: "1200px",
            paddingLeft: "2rem",
            paddingRight: "2rem",
          }}
        >
          <div
            className="bg-white shadow-lg ring-1 ring-black ring-opacity-5 d-grid gap-3"
            style={{
              padding: "2rem",
              marginTop: "2rem",
              marginBottom: "2rem",
              borderRadius: "0.375rem",
            }}
          >
            <div className="d-flex flex-column flex-wrap justify-content-between items-start gap-1">
              <div className="d-flex justify-content-between items-center">
                <img
                  src="/img/general/lunabooking.png"
                  alt="logo icon"
                  style={{
                    width: "8rem",
                  }}
                />
                <div className="text-dark-1 text-30 fw-700">
                  Booking Details
                </div>
              </div>
              <div
                className="w-100"
                style={{
                  height: "3px",
                  backgroundColor: "#e5e7eb",
                }}
              ></div>
            </div>
            <div>
              <div className="text-20 fw-600">Booking ID</div>
              <div className="text-16 fw-500">{booking._id}</div>
            </div>
            <div>
              <div className="text-20 fw-600">Departure Information</div>
              <div
                className="w-100"
                style={{
                  height: "3px",
                  backgroundColor: "#e5e7eb",
                }}
              ></div>
              <div className="d-flex flex-column mt-4">
                <div className="d-flex">
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{
                      flexBasis: "33.333333%",
                    }}
                  >
                    Provider
                  </div>
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{ flexBasis: "1rem" }}
                  >
                    :
                  </div>
                  <div className="d-flex items-center gap-2">
                    {booking.schedule.provider.image && (
                      <div className="mb-1">
                        <img
                          src={
                            process.env.REACT_APP_API_URL +
                            booking.schedule.provider.image
                          }
                          style={{ width: "1.25rem", borderRadius: "0.625rem" }}
                        />
                      </div>
                    )}
                    <div className="text-16 fw-500">
                      {booking.schedule.provider.name}
                    </div>
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{
                      flexBasis: "33.333333%",
                    }}
                  >
                    Route
                  </div>
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{ flexBasis: "1rem" }}
                  >
                    :
                  </div>
                  <div className="text-16 fw-500">
                    {booking.schedule.origin.country},{" "}
                    {booking.schedule.origin.port} -{" "}
                    {booking.schedule.destination.country},{" "}
                    {booking.schedule.destination.port}
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{
                      flexBasis: "33.333333%",
                    }}
                  >
                    Date
                  </div>
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{ flexBasis: "1rem" }}
                  >
                    :
                  </div>
                  <div className="text-16 fw-500">
                    {booking.schedule.departure &&
                      new Date(booking.schedule.departure).toLocaleString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "long",
                          year: "numeric",
                          timeZone:
                            Intl.DateTimeFormat().resolvedOptions().timeZone,
                        }
                      )}
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{
                      flexBasis: "33.333333%",
                    }}
                  >
                    Time
                  </div>
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{ flexBasis: "1rem" }}
                  >
                    :
                  </div>
                  <div className="text-16 fw-500">
                    {booking.schedule.departure &&
                      new Date(booking.schedule.departure).toLocaleString(
                        "en-GB",
                        {
                          timeStyle: "short",
                        }
                      )}
                  </div>
                </div>
                <div className="d-flex">
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{
                      flexBasis: "33.333333%",
                    }}
                  >
                    Trip Type
                  </div>
                  <div
                    className="text-16 fw-600 flex-shrink-0"
                    style={{ flexBasis: "1rem" }}
                  >
                    :
                  </div>
                  <div className="text-16 fw-500">
                    {booking.isTwoWay ? "Round-trip" : "One-way"}
                    {booking.isTwoWay && (
                      <div className="mt-0.5">
                        <div className="text-16 fw-600">
                          Return Ticket is an Open ticket, valid 90 days after
                          purchased.
                        </div>
                        <div className="text-16 fw-500">
                          Collect return ticket from counter.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="text-20 fw-600">Passenger Details</div>
              <div
                className="w-100"
                style={{
                  height: "3px",
                  backgroundColor: "#e5e7eb",
                }}
              ></div>
              <table className="w-100 border" cellSpacing={8} cellPadding={8}>
                <thead>
                  <tr>
                    <th className="px-8 text-center">Passport</th>
                    <th className="px-8 text-center">Name</th>
                    <th className="px-8 text-center">
                      Gender <br />
                      (M/F)
                    </th>
                    <th className="px-8 text-center">
                      Nationality <br />
                      (Country)
                    </th>
                    <th className="px-8 text-center">
                      Date of Birth <br />
                      (DD/MM/YYYY)
                    </th>
                    <th className="px-8 text-center">
                      Issuing Date <br />
                      (DD/MM/YYYY)
                    </th>
                    <th className="px-8 text-center">
                      Expired Date <br />
                      (DD/MM/YYYY)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {booking.tickets.map((ticket, idx) => (
                    <React.Fragment key={idx}>
                      <tr>
                        <td
                          colSpan={7}
                          className="w-100"
                          style={{
                            height: "0.5px",
                            borderBottomWidth: "1px",
                            borderColor: "#e5e7eb",
                          }}
                        ></td>
                      </tr>
                      <tr>
                        <td className="px-8 text-center">
                          {ticket.passportNumber}
                        </td>
                        <td className="px-8 text-center">{ticket.fullName}</td>
                        <td className="px-8 text-center">
                          {ticket.gender.charAt(0).toUpperCase()}
                        </td>
                        <td className="px-8 text-center">
                          {ticket.nationality}
                        </td>
                        <td className="px-8 text-center">
                          {new Date(ticket.dateOfBirth).toLocaleString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td className="px-8 text-center">
                          {new Date(ticket.issuingDate).toLocaleString(
                            "en-GB",
                            {
                              day: "numeric",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td className="px-8 text-center">
                          {new Date(ticket.expiredDate).toLocaleString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
            <div>
              <div className="text-20 fw-600">
                Ferry Ticket Fare & Service Fees Details
              </div>
              <div
                className="w-100"
                style={{
                  height: "3px",
                  backgroundColor: "#e5e7eb",
                }}
              ></div>
              <div className="d-flex justify-content-between mt-4 mb-4">
                <div
                  className="d-flex justify-content-center items-center"
                  style={{
                    flexBasis: "33.333333%",
                  }}
                >
                  <img
                    src="/img/icons/checkCircle.svg"
                    alt="checkCircle.svg"
                    style={{
                      width: "16.25px",
                    }}
                  />
                  <div className="text-16 fw-600">Ferry Ticket Paid</div>
                </div>
                <div
                  className="d-flex justify-content-center items-center"
                  style={{
                    flexBasis: "33.333333%",
                  }}
                >
                  <img
                    src="/img/icons/checkCircle.svg"
                    alt="checkCircle.svg"
                    style={{
                      width: "16.25px",
                    }}
                  />
                  <div className="text-16 fw-600">Include Sea Port Tax</div>
                </div>
              </div>
              <ol
                style={{
                  paddingLeft: "0",
                }}
              >
                <li className="fw-500">
                  1. You are required to collect your boarding pass 90 mins
                  before your departure time or seats will be automatically
                  released.
                </li>
                <li className="fw-500">
                  2. This fare type is NOT transferable to another passenger.
                </li>
                <li className="fw-500">
                  3. This fare type is capacity controlled and limited and
                  hence, may not be available on all ferries.
                </li>
                <li className="fw-500">
                  4. This fare type is always subject to our General Terms and
                  Conditions
                </li>
              </ol>
            </div>
            <div>
              <div className="text-20 fw-600">Travel Guide</div>
              <div
                className="w-100 mb-4"
                style={{
                  height: "3px",
                  backgroundColor: "#e5e7eb",
                }}
              ></div>
              <ul>
                <li className="fw-500">
                  * Use our Self Check-In facilities, log onto our website on
                  the facilities available
                </li>
                <li className="fw-500">
                  * Check-in deadlines may vary at different seaports and for
                  particular ferries, comply with these deadlines.
                </li>
                <li className="fw-500">
                  * It is your responsibility to ensure that you do not carry
                  any liquids, aerosols and gels more than 100ml in your cabin
                  baggage.
                </li>
                <li className="fw-500">
                  * Some seaporsts have long queues due to complex security
                  checks. We suggest that you check-in early and proceed
                  immediately to the departure gate or you may risk being left
                  behind in the security.
                </li>
                <li className="fw-500">
                  * Guests may not use the unused checked baggage weight of
                  other guests unless travelling on the same Itineray.
                </li>
                <li className="fw-500">
                  * Guests are strongly advised not to check in goods which are
                  valuable and fragile as baggage.
                </li>
                <li className="fw-500">
                  * You are responsible for obtaining all valid entry and exit
                  visas, health and other required documents, ensuring that your
                  passport meets the destination's entry requirements.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ItinerayForm;
