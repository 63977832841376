import http from "../axios";

export const getBookings = (
  status?: string,
  page: number = 1,
  limit: number = 10,
  userId?: string
) => {
  const query: any = {
    page: page,
    limit: limit,
  };
  if (!!status) {
    query.status = status;
  }
  if (!!userId) {
    query.user = userId;
  }
  return http.get(`/admin/bookings`, {
    params: query,
  });
};

export const getBooking = (id: string) => {
  return http.get(`/admin/bookings/${id}`);
};

export const getBookingCount = (date: string) => {
  return http.get(`/admin/bookings/count`, { params: { date: date } });
};

export const rejectPaidBooking = (id: string, reason: string) => {
  return http.patch(`/admin/bookings/${id}/reject`, {
    reason,
  });
};

export const rejectCancellationRequestBooking = (
  id: string,
  reason: string
) => {
  return http.patch(`/admin/bookings/${id}/cancellation/reject`, {
    reason,
  });
};

export const approveCancellationRequestBooking = (id: string) => {
  return http.patch(`/admin/bookings/${id}/cancellation/approve`);
};

export const processBooking = (id: string) => {
  return http.patch(`/admin/bookings/${id}/process`);
};

export const issueBooking = (
  id: string,
  tickets: Array<{ ticketId: string; ticketCode: string }>
) => {
  return http.patch(`/admin/bookings/${id}/issue`, {
    tickets: tickets,
  });
};
