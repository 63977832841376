import { FC, PropsWithChildren } from "react";
import { Table } from "react-bootstrap";
import Pagination from "../common/Pagination";
import dayjs from "dayjs";
import { WalletMutation } from "../../../interfaces/mutation.interface";

export interface WalletMutationTableProps extends PropsWithChildren {
  className: string,
  page: number,
  total: number,
  data: Array<WalletMutation>,
  onPageChange: (page:number) => void
}

const WalletMutationTable:FC<WalletMutationTableProps> = (props) => {
  return (
    <div className={props.className || ""}>
      <div style={{maxHeight: "calc(100% - 120px)", height: "100%"}} className="overflow-y-scroll">
        <Table striped responsive>
          <thead>
            <tr>
              <th>Date</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Balance</th>
              <th>Remarks</th>
              <th>By</th>
            </tr>
          </thead>
          <tbody>
            {
              props.data && props.data.length > 0 ? (
                props.data.map((dt, i)=> {
                  return (
                    <tr key={`user-${i}`}>
                      <td>{dayjs(dt.createdAt).format("DD-MMM-YYYY HH:mm:ss")}</td>
                      <td>{dt.type}</td>
                      <td className={`${dt.credit > 0 ? "text-success" : "text-danger"}`}>{dt.credit > 0 ? dt.credit : dt.debit}</td>
                      <td>{dt.balance}</td>
                      <td>{dt.remarks}</td>
                      <td>{dt.by}</td>
                    </tr>
                  )
                })
              )  : 
              <tr>
                <td className="text-center" colSpan={5}>No Data Available</td>
              </tr>
            }
          </tbody>
        </Table>
      </div>
      <Pagination page={props.page} totalPage={props.total} onPageClick={(page)=>props.onPageChange(page)} />
    </div>
  );
};

export default WalletMutationTable;
