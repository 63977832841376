import { ReportsBookingsRequest } from "../../interfaces/reports.interface";
import http from "../axios";

export const getSummary = () => {
  return http.get("/admin/reports/dashboard/summary");
};

export const getWeekly = () => {
  return http.get("/admin/reports/dashboard/weekly");
};

export const getReportsBookings = (params: ReportsBookingsRequest) => {
  return http.get("/admin/reports/bookings", { params: params });
};
