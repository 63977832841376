import { ScheduleStatus } from "../../enums/schedule.enum"
import { Schedule } from "../../interfaces/schedule.interface"
import http from "../axios"

export const getSchedules = (provider?:string, fromDeparture?:string, toDeparture?:string, status?:ScheduleStatus, 
  page:number = 1, limit:number = 10) => {
  const query = [`page=${page}`, `limit=${limit}`];
  if (provider !== undefined) {
    query.push(`provider=${provider}`);
  }
  if (fromDeparture !== undefined) {
    query.push(`fromDeparture=${fromDeparture}`);
  }
  if (toDeparture !== undefined) {
    query.push(`toDeparture=${toDeparture}`);
  }
  if (status !== undefined) {
    query.push(`status=${status}`);
  }
  return http.get(`/admin/schedules?${query.join("&")}`)
}

export const getSchedule = (id:string) => {
  return http.get(`/admin/schedules/${id}`)
}

export const updateSchedule = (id:string, schedule:Schedule) => {
  return http.patch(`/admin/schedules/${id}`, schedule);
}

export const addSchedule = (data:{departures:string[], schedule:Schedule}) => {
  return http.post("/admin/schedules", data);
}