import { FC, PropsWithChildren, useState } from "react";
import Pagination from "../common/Pagination";
import { Badge, Button, Form, Table } from "react-bootstrap";
import dayjs from "dayjs";
import Dropdown from 'react-bootstrap/Dropdown';
import { Booking, BookingUser } from "../../../interfaces/booking.interface";
import { convertToRupiah } from "../../../utils/convertToRupiah";
import { BookingStatus } from "../../../enums/booking.enum";
import React from "react";
import { useAuth } from "../../../contexts/AuthContext";

export interface BookingTableProps extends PropsWithChildren {
  className: string,
  page: number,
  total: number,
  data: Array<Booking>,
  onPageChange: (page:number) => void,
  onApproveCancellationRequest: (booking:Booking) => void,
  onRejectCancellationRequest: (booking:Booking) => void,
  onRejectPaidBooking: (booking:Booking) => void,
  onProcessBooking: (booking:Booking) => void,
  onViewBooking: (booking:Booking) => void
}

const BookingTable:FC<BookingTableProps> = (props) => {

  const lists = [
    { 
      status: "waiting_for_payment",
      menus: [
        { value: "", label: "" }
      ] 
    }
  ];

  const { user }  = useAuth();
  const renderActionButton = (booking:Booking) => {
    switch(booking.status) {
      case BookingStatus.PROCESSING:
        if ((booking.processedBy as BookingUser)._id == user?._id) {
          return <Button className="w-100 text-14" onClick={(e) => props.onProcessBooking(booking)}>Continue Process</Button>
        }  else {
          return <Button disabled className="w-100 text-14" onClick={(e) => props.onProcessBooking(booking)}>{`Processing By ${booking.processedBy}`}</Button>
        }
      case BookingStatus.PAID:
        return <div className="w-100 gap-1 d-flex">
          <Button className="w-full flex-1 text-14" onClick={(e) => props.onProcessBooking(booking)} >Process</Button>
          <Button className="w-full flex-1 text-14" onClick={(e) => props.onRejectPaidBooking(booking)} >Reject</Button>
        </div>
      case BookingStatus.REQUEST_FOR_CANCELLATION:
        return <div className="w-100">
          <Button className="flex-grow-1 text-14" onClick={(e) => props.onApproveCancellationRequest(booking)}>Approve</Button>
          <Button className="flex-grow-1 text-14" onClick={(e) => props.onRejectCancellationRequest(booking)}>Reject</Button>
        </div>
      default:
        return <Button className="w-100 text-14" onClick={(e) => props.onViewBooking(booking)}>View</Button>;
    }
  }

  return (
    <div className={props.className || ""} >
      <div className="tabs__content js-tabs-content">
        <div className="tabs__pane -tab-item-1 is-tab-el-active">
          <div className="overflow-scroll scroll-bar-1">
            <Table size="sm" striped responsive>
              <thead className="bg-light-2">
                <tr>
                  <th>Provider</th>
                  <th>Way</th>
                  <th>Departure</th>
                  <th>User</th>
                  <th>Tickets</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {
                  props.data.map((booking, idx) => {
                    const data = booking.tickets.reduce((prev, current) => {
                      prev.price = prev.price + current.price;
                      prev.passengers = prev.passengers + 1;
                      return prev;
                    }, {
                      price: 0,
                      passengers: 0
                    });
                    const status = booking.status.split("_").map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
                    let statusBadge = "success";
                    switch(booking.status) {
                      case BookingStatus.CANCELLED:
                      case BookingStatus.EXPIRED:
                      case BookingStatus.REJECTED:
                        statusBadge = "danger";
                        break;
                      case BookingStatus.WAITING_FOR_PAYMENT:
                      case BookingStatus.REQUEST_FOR_CANCELLATION:
                      case BookingStatus.PAID:
                      case BookingStatus.PROCESSING:
                        statusBadge = "warning";
                        break;
                      case BookingStatus.ISSUED:
                        statusBadge = "success";
                        break;
                    }
                    return (
                    <tr key={`row-booking-${idx}`}>
                      <td>{booking.schedule.provider.name}</td>
                      <td>{`${booking.schedule.origin.country} ${String.fromCharCode(8594)} ${booking.schedule.destination.country}`}</td>
                      <td>{`${dayjs(booking.schedule.departure).format("HH:mm DD-MMM-YYYY")}`}</td>
                      <td><a className="text-blue-1 underline" href={`/users/${(booking.user as BookingUser)._id}`}>{(booking.user as BookingUser).email}</a></td>
                      <td className="fw-500">{`${data.passengers} Pax`}</td>
                      <td>{convertToRupiah(data.price)}</td>
                      <td>
                        <Badge bg={statusBadge}>{status}</Badge>
                      </td>
                      <td>
                        {
                          renderActionButton(booking)
                        }
                      </td>
                    </tr>)
                  })
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <Pagination page={props.page} totalPage={props.total} onPageClick={props.onPageChange} />
    </div>
  );
};

export default BookingTable;
