import { useState, useEffect } from "react";
import axios from "axios";
import { PageConfig } from "../../router";
import DashboardCard from "../../components/dashboard/dashboard/DashboardCard";
import ChartMain from "../../components/dashboard/dashboard/ChartMain";
import { useApp } from "../../contexts/AppContext";
import { getReportsBookings, getWeekly } from "../../utils/rest-api/reports";
import {
  ReportsBookingsItem,
  ReportWeekly,
} from "../../interfaces/reports.interface";
import MetaComponent from "../../components/common/MetaComponent";
import { DateObject } from "react-multi-date-picker";

const metadata = {
  title: "Luna - Admin Dashboard",
  description: "Luna - Admin Dashboard",
};

export default function DashboardPage() {
  const { showLoader, hideLoader } = useApp();
  const [weekly, setWeekly] = useState<Array<ReportWeekly>>([]);
  const [reportsBookings, setReportsBookings] =
    useState<Array<ReportsBookingsItem>>();

  const fetchData = () => {
    showLoader();
    let date = new DateObject().format("YYYY-MM-DD");
    axios
      .all([
        getWeekly(),
        getReportsBookings({ startDate: date, endDate: date }),
      ])
      .then(
        axios.spread((res1, res2) => {
          // output of req.
          let reportWeekly = res1.data.data as Array<ReportWeekly>;
          setWeekly(reportWeekly);
          let reportsBookings = res2.data.data as Array<ReportsBookingsItem>;
          setReportsBookings(reportsBookings);
        })
      )
      .finally(() => {
        hideLoader();
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <MetaComponent meta={metadata} />
      <div className="dashboard__main h-100">
        <div className="dashboard__content bg-light-2 h-100 w-100">
          <div className="d-flex flex-column h-100 mb-20 overflow-y-scroll">
            <div className="d-flex flex-row mb-20">
              <span className="text-30 lh-14 fw-600">Dashboard</span>
            </div>
            <div className="h-100">
              <h2 className="text-18 lh-1 fw-500 pb-20">
                Today's booking Statistics
              </h2>
              {reportsBookings && (
                <DashboardCard reportsBookings={reportsBookings} />
              )}
              <div className="row y-gap-30 pt-20 chart_responsive">
                <div className="col-xl-7 col-md-6">
                  <div className="py-30 px-30 rounded-4 bg-white shadow-3">
                    <div className="d-flex justify-between items-center">
                      <h2 className="text-18 lh-1 fw-500">
                        Weakly's booking Statistics
                      </h2>
                      {/* <ChartSelect /> */}
                    </div>
                    <div className="pt-30">
                      <ChartMain
                        datasets={weekly.map((day) => {
                          return {
                            label: day.date,
                            amount: day.amount,
                            count: day.count,
                          };
                        })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export const DashboardPageConfig: PageConfig = {
  path: "/",
  component: DashboardPage,
  roles: ["admin", "root"],
};
